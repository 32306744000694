<template>
  <div>
    <ul class="tab">
      <li class="tile tile--tab tab__item">
        <router-link :to="{ name: 'CrowdfundingUsers' }" tag="p" exact class="tile__content text-uppercase"
          >Joined Users</router-link
        >
      </li>
      <li class="tile tile--tab tab__item active">
        <router-link :to="{ name: 'CrowdfundingFeeds' }" tag="p" exact class="tile__content text-uppercase"
          >Can Join</router-link
        >
      </li>
    </ul>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <div class="d-flex">
        <div class="search-box-group mr-8">
          <search-box v-model="searchParams.searchText" holder-text="Search" @onSearch="onGetCrowdfundingFeeds" />

          <selection-box
            v-model="searchParams.searchTarget"
            :options="searchTargetOptions"
            :is-align-right="true"
            :on-check="onGetCrowdfundingFeedsByTarget"
            selected-box-class="is-md search-box__filter"
          />
        </div>
        <button class="btn btn--md btn--primary mr-8" @click="onGetCrowdfundingFeeds">Search</button>
      </div>

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetCrowdfundingFeeds"
      />
    </div>

    <div
      v-if="feeds && feeds.data && feeds.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Wallet address</th>
            <th>Email</th>
            <th>Deposited Amount<br />(TOKO)</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="feed in feeds.data"
            :key="feed.id"
            :to="{ name: 'CrowdfundingFeed', params: { id: feed.id } }"
            tag="tr"
            class="link--clone"
          >
            <td :class="{ 'badge badge--triangle-shape tooltip': feed.user.blacklist > 0 }">
              {{ feed.user.id }}
              <span class="tooltip__text" v-if="feed.user.blacklist > 0">{{
                feed.user.blacklist | format_blacklist_type
              }}</span>
            </td>
            <td class="clipboard-copy wr-25">
              <span class="text-ellipsis clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ feed.address }}</span>
                <copy-clipboard @on-copy="doCopy($event)" :external-link="`/etherscan-address/${feed.address}`" />
              </span>
            </td>
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ feed.user.email }}</span>
                <copy-clipboard :is-external="false" tooltip-class="custom" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td>{{ feed.totalAmountDeposit }}</td>
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ feeds.total | format_user_count_text }} in total</p>

        <pagination
          v-if="isNextPage(feeds.total)"
          v-model="page"
          :page-count="getPageCount(feeds.totalPages, feeds.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <p v-else class="mt-16">No data</p>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pagination from '@/mixins/pagination'
import { PAGER_OPTIONS } from '@/utils/data-sets'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
import handleAssets from '@/mixins/handle-assets'
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      params: {
        perPage: 20,
        address: null,
        email: null
      },
      searchParams: {
        searchText: '',
        searchTarget: 0
      },
      pagerOptions: PAGER_OPTIONS,
      page: 1,
      searchTargetOptions: [
        { value: 0, name: 'Email', disabled: false },
        { value: 1, name: 'Wallet Address', disabled: false }
      ]
    }
  },

  mixins: [pagination, toggleLoading, copyClipboard, handleAssets],

  components: {
    Pagination,
    SelectionBox,
    SearchBox,
    CopyClipboard
  },

  computed: {
    ...mapState('crowdfunding', ['feeds', 'feedAssets']),

    searchTarget() {
      return parseInt(this.searchParams.searchTarget)
    }
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingFeeds']),

    onGetCrowdfundingFeeds() {
      this.toggleLoading()
      this.page = 1
      this.getCrowdfundingFeeds(this.getCrowdfundingFeedsParams()).finally(() => {
        this.toggleLoading()
      })
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getCrowdfundingFeeds(this.getCrowdfundingFeedsParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getCrowdfundingFeedsParams() {
      let walletAddress, email
      if (this.searchParams.searchText) {
        email = this.searchTarget === 0 ? this.searchParams.searchText : this.params.email
        walletAddress = this.searchTarget === 1 ? this.searchParams.searchText : this.params.address
      }

      return {
        page: this.page,
        limit: this.params.perPage,
        walletAddress,
        email
      }
    },

    onGetCrowdfundingFeedsByTarget() {
      if (!this.searchParams.searchText) return
      this.onGetCrowdfundingFeeds()
    }
  },

  created() {
    this.getCrowdfundingFeeds(this.getCrowdfundingFeedsParams()).finally(() => {
      this.toggleLoading()
    })
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'CrowdfundingFeed') {
      this.storeAssets('crowdfunding', 'STORE_FEED_ASSETS')
      next()
    } else {
      this.clearAssets('crowdfunding', 'CLEAR_FEED_ASSETS')
      next()
    }
  }
}
</script>
